import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../../Navbar";
import ApplicantsCard from "../../ApplicantsCard/ApplicantsCard";
import { view_applications } from "../../../utilities/apis";
import { setApplications } from "../../../app/state/userSlice";
import Loader from "../../Loader"; // Assuming you have the Loader component

const ViewApplicants = () => {
  const location = useLocation();
  const job_id = location.state?.job_id;

  const [selectedButton, setSelectedButton] = useState("All");
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    experienceMin: "",
    experienceMax: "",
    location: "",
    noticePeriod: "",
    salaryMin: "",
    salaryMax: "",
    keySkills: "",
  });
  const [appliedFilters, setAppliedFilters] = useState({
    experienceMin: "",
    experienceMax: "",
    location: "",
    noticePeriod: "",
    salaryMin: "",
    salaryMax: "",
    keySkills: "",
  });
  const [customLocation, setCustomLocation] = useState("");

  const userName = useSelector((state) => state.user.userName);
  const selectJobTitle = useSelector((state) => state.user.applicant_jobTitle);
  const applicants = useSelector((state) => state.user.applications);
  const dispatch = useDispatch();

  const buttons = ["All", "Shortlisted", "Rejected", "New"];

  useEffect(() => {
    if (job_id) {
      viewApplicants();
    }
  }, [job_id]);

  useEffect(() => {
    filterApplicants(); // This already updates your filteredApplicants based on the tab.
    // Assuming filteredApplicants is updated here correctly.
  }, [selectedButton]); // Add dependencies as needed

  useEffect(() => {
    filterApplicants();
  }, [applicants, selectedButton, appliedFilters]);

  const viewApplicants = async () => {
    setLoading(true);
    dispatch(setApplications([]));
    const applicants_res = await view_applications({ job_id });
    if (applicants_res.success) {
      const updatedApplications = applicants_res.applications.map(
        (applicant) => ({
          ...applicant,
          status: applicant.status || "New",
          photo: applicant.photo, // Assuming 'photo' is the key in the response
        })
      );
      dispatch(setApplications(updatedApplications));
    } else {
      dispatch(setApplications([]));
    }
    setLoading(false);
  };

  const handleTabSelection = (label) => {
    setSelectedButton(label);
  };

  const filterApplicants = () => {
    let newApplicants = applicants;

    if (selectedButton !== "All") {
      const statusMapping = {
        Shortlisted: "Shortlist",
        Rejected: "Reject",
        New: "New",
        Blacklisted: "Blacklist",
      };
      newApplicants = newApplicants.filter(
        (applicant) => applicant.status === statusMapping[selectedButton]
      );
    }

    if (appliedFilters.experienceMin || appliedFilters.experienceMax) {
      newApplicants = newApplicants.filter((applicant) => {
        const experience = parseFloat(applicant.experience);
        const min = parseFloat(appliedFilters.experienceMin);
        const max = parseFloat(appliedFilters.experienceMax);
        return (
          (isNaN(min) || experience >= min) && (isNaN(max) || experience <= max)
        );
      });
    }

    if (appliedFilters.location) {
      const filterLocation = appliedFilters.location.toLowerCase();
      newApplicants = newApplicants.filter((applicant) => {
        const applicantLocations = Array.isArray(applicant.location)
          ? applicant.location
          : [applicant.location];
        return applicantLocations.some((loc) =>
          loc.toLowerCase().includes(filterLocation)
        );
      });
    }

    if (appliedFilters.noticePeriod) {
      newApplicants = newApplicants.filter((applicant) => {
        return (
          parseInt(applicant.notice_period) <=
          parseInt(appliedFilters.noticePeriod)
        );
      });
    }

    if (appliedFilters.salaryMin || appliedFilters.salaryMax) {
      newApplicants = newApplicants.filter((applicant) => {
        const salary = applicant.ctc
          ? parseFloat(applicant.ctc.replace(/[^0-9.-]+/g, ""))
          : NaN;
        const min = parseFloat(appliedFilters.salaryMin);
        const max = parseFloat(appliedFilters.salaryMax);
        return (isNaN(min) || salary >= min) && (isNaN(max) || salary <= max);
      });
    }

    if (appliedFilters.keySkills) {
      const skillsArray = appliedFilters.keySkills
        .toLowerCase()
        .split(",")
        .map((skill) => skill.trim());
      newApplicants = newApplicants.filter((applicant) => {
        return (
          Array.isArray(applicant.skills) &&
          skillsArray.every((skill) =>
            applicant.skills
              .map((reqSkill) => reqSkill.toLowerCase())
              .includes(skill)
          )
        );
      });
    }

    setFilteredApplicants(newApplicants);
  };

  const updateApplicantStatus = (email, newStatus) => {
    const updatedApplicants = applicants.map((applicant) =>
      applicant.email === email
        ? { ...applicant, status: newStatus }
        : applicant
    );
    dispatch(setApplications(updatedApplicants));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (name === "location" && value !== "Other") {
      setCustomLocation("");
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.toString(), // Ensure the value is a string
    }));
  };

  const handleCustomLocationChange = (event) => {
    setCustomLocation(event.target.value);
  };

  const applyFilters = () => {
    const updatedFilters = { ...filters };
    if (filters.location === "Other") {
      updatedFilters.location = customLocation;
    }
    setAppliedFilters(updatedFilters);
  };

  const clearFilters = () => {
    setFilters({
      experienceMin: "",
      experienceMax: "",
      location: "",
      noticePeriod: "",
      salaryMin: "",
      salaryMax: "",
      keySkills: "",
    });
    setAppliedFilters({
      experienceMin: "",
      experienceMax: "",
      location: "",
      noticePeriod: "",
      salaryMin: "",
      salaryMax: "",
      keySkills: "",
    });
    setCustomLocation("");
  };

  const getUniqueLocations = () => {
    const locations = applicants.flatMap((applicant) =>
      applicant.location && Array.isArray(applicant.location)
        ? applicant.location.flatMap((loc) =>
            loc.split(",").map((subLoc) => subLoc.trim())
          )
        : []
    );
    return [...new Set(locations)].filter((location) => location !== "");
  };

  const uniqueLocations = getUniqueLocations();

  return (
    <div className="min-h-screen bg-gray-100 overflow-auto">
      <NavBar logOutFlag={true} username={userName} />
      <div className="flex flex-col md:flex-row m-6 bg-white p-4 rounded-lg justify-between items-center gap-4">
        <h5 className="text-xl font-semibold">{selectJobTitle}</h5>
        <div className="flex gap-4">
          {buttons.map((label) => (
            <button
              key={label}
              className={`px-4 py-2 rounded ${
                selectedButton === label
                  ? "bg-blue-600 text-white"
                  : "bg-transparent text-blue-600 border border-blue-600"
              }`}
              onClick={() => handleTabSelection(label)}
            >
              {label}
            </button>
          ))}
        </div>
        <a href="/home" className="text-blue-600">
          Back to home
        </a>
      </div>
      <div className="border-t my-4" />
      <div className="flex m-4">
        <div className="w-1/4 bg-white p-4 rounded-md shadow-md">
          <div className="flex justify-between items-center mb-4">
            <div className="text-lg font-semibold">Filters</div>
          </div>
          <div className="border-t" />
          <div className="mt-4 space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Experience
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  name="experienceMin"
                  value={filters.experienceMin}
                  onChange={handleFilterChange}
                  placeholder="Min"
                  className="w-1/2 p-2 border rounded mr-2"
                  min="0"
                  step="1"
                />
                <input
                  type="number"
                  name="experienceMax"
                  value={filters.experienceMax}
                  onChange={handleFilterChange}
                  placeholder="Max"
                  className="w-1/2 p-2 border rounded"
                  min="0"
                  step="1"
                />
              </div>
            </div>
            <div className="border-t" />
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Salary
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  name="salaryMin"
                  value={filters.salaryMin}
                  onChange={handleFilterChange}
                  placeholder="Min"
                  className="w-1/2 p-2 border rounded mr-2"
                  min="0"
                  step="1"
                />
                <input
                  type="number"
                  name="salaryMax"
                  value={filters.salaryMax}
                  onChange={handleFilterChange}
                  placeholder="Max"
                  className="w-1/2 p-2 border rounded"
                  min="0"
                  step="1"
                />
              </div>
            </div>
            <div className="border-t" />
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <select
                name="location"
                value={filters.location}
                onChange={handleFilterChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Location</option>
                {uniqueLocations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {filters.location === "Other" && (
                <input
                  type="text"
                  name="customLocation"
                  value={customLocation}
                  onChange={handleCustomLocationChange}
                  placeholder="Enter location"
                  className="mt-2 w-full p-2 border rounded"
                />
              )}
            </div>
            <div className="border-t" />
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Notice Period
              </label>
              <input
                type="number"
                name="noticePeriod"
                value={filters.noticePeriod}
                onChange={handleFilterChange}
                placeholder="Days"
                className="w-full p-2 border rounded"
                min="0"
                step="1"
              />
            </div>
            <div className="border-t" />
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Key Skills
              </label>
              <input
                type="text"
                name="keySkills"
                value={filters.keySkills}
                onChange={handleFilterChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={applyFilters}
                className="px-4 py-2 bg-blue-600 text-white rounded"
              >
                Apply
              </button>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-gray-400 text-white rounded"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 ml-4" style={{ position: "relative" }}>
          {loading ? (
            <Loader />
          ) : Array.isArray(filteredApplicants) &&
            filteredApplicants.length > 0 ? (
            filteredApplicants.map((applicant, index) => (
              <ApplicantsCard
                key={`${applicant.email}-${selectedButton}`}
                applicantDetails={applicant}
                jobId={job_id}
                onUpdateStatus={updateApplicantStatus}
                photo={applicant.photo}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-96 text-xl text-gray-500">
              No applicants found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewApplicants;
