import { useSelector } from "react-redux";
import { store } from "../app/store";
import {
  SIGNUP,
  VALIDATE_OTP,
  GENERATE_OTP,
  DOMAIN,
  CREATEJOB,
  UPLOAD_RESUME,
  SEARCH_JOB,
  LIST_JOB,
  EDIT_JOB,
  DELETE_JOB,
  GET_USER_SKILLS,
  APPLY_JOB,
  VIEW_APPLICATIONS,
  DOWNLOAD_RESUME,
  VIEW_USERS_BY_STATUS,
  UPDATE_STATUS,
  AUTO_LOGIN,
  EDIT_PROFILE
} from "./apiEndpoints";


const getToken = () => store.getState().auth.token;

async function makeApiRequest(endpoint, method, requestBody) {
  try {
    const token = getToken()
    
    const url = DOMAIN + endpoint;
    const config = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...(token && { "Authorization": `Bearer ${token}` }),
      },

      credentials: "include",
    };
    if (method === "POST" || method === "DELETE") {
      config.body = JSON.stringify(requestBody);
    }
    const response = await fetch(url, config);

    if (!response.ok) {
      throw new Error(`HTTP error!`);
    }

    try {
      const data = await response.json();
      console.log(`${endpoint}: ${data}`);
      return data;
    } catch (jsonError) {
      console.log(`${endpoint} Error: ${jsonError}`);
      throw new Error("Failed to parse JSON response.");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function ResumeDownload(userId, userName) {
  try {
    const token = getToken()
    const url = DOMAIN + DOWNLOAD_RESUME;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { "Authorization": `Bearer ${token}` }),
      },
      body: JSON.stringify({ user_id: userId }),
    };

    const response = await fetch(url, config);

    if (!response.ok) {
      const errorMessage = `HTTP error! Status: ${response.status} - ${response.statusText}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    console.log("RESPONSE: ", response);

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${userName}_Resume`; // Adjust the filename and extension as needed
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
}

export async function generateOTP(user_id) {
  return await makeApiRequest(GENERATE_OTP, "POST", { user_id });
}

export async function validateOTP(otp, user_id) {
  return await makeApiRequest(VALIDATE_OTP, "POST", { otp, user_id });
}

export async function signUp(userData) {
  return await makeApiRequest(SIGNUP, "POST", userData);
}

// export async function updateProfile(userdata) {
//   return await makeApiRequest(updateProfile, "POST", userdata);
// }

export async function autoLoginViaToken(){
  return await makeApiRequest(AUTO_LOGIN,"GET",null);
}

export async function search_jobs(searchQuery) {
  return await makeApiRequest(SEARCH_JOB, "POST", searchQuery);
}

export async function list_jobs(userData) {
  return await makeApiRequest(LIST_JOB, "POST", userData);
}

export async function get_skills(userId) {
  return await makeApiRequest(GET_USER_SKILLS, "POST", userId);
}

export async function del_jobs(jobId, userId) {
  return await makeApiRequest(
    DELETE_JOB + "/" + jobId,
    "DELETE",
    userId,
  );
}

export async function apply_job(applyDetails) {
  return await makeApiRequest(APPLY_JOB, "POST", applyDetails);
}

export async function view_applications(job_id) {
  return await makeApiRequest(VIEW_APPLICATIONS, "POST", job_id);
}

export async function viewByStatus(job_id,status ){
  return await makeApiRequest(VIEW_USERS_BY_STATUS, "POST",{job_id,status})
}

export const edit_job = async (formData) => {
  try {
    const token = getToken();
    const response = await fetch(DOMAIN + EDIT_JOB, {
      method: "POST",
      //should not include any headers !IMP
      headers:{
        
        ...(token && { "Authorization": `Bearer ${token}` }),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorMessage = await response.json();
      throw new Error(errorMessage.error || "Failed to upload resume");
    }

    const editedJob = await response.json();
    console.log(editedJob);
    return editedJob;
  } catch (error) {
    console.error("Error uploading resume:", error.message);
    // Handle error
  }
};

export const uploadResume = async (formData) => {
  try {
    const token = getToken();
    const response = await fetch(DOMAIN + UPLOAD_RESUME, {
      method: "POST",
      //should not include any headers !IMP
      headers:{
        ...(token && { "Authorization": `Bearer ${token}` }),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorMessage = await response.json();
      throw new Error(errorMessage.error || "Failed to upload resume");
    }

    const resumeData = await response.json();
    // Handle the resume data returned from the server
    console.log(resumeData);
    return resumeData;
  } catch (error) {
    console.error("Error uploading resume:", error.message);
    // Handle error
  }
};

export async function createJob(formData) {
  const token = getToken();
  await fetch(DOMAIN + CREATEJOB, {
    method: "POST",
    headers:{
      ...(token && { "Authorization": `Bearer ${token}` }),
    },
    body: formData,
  })
    .then((res) => {
      console.log("Response: ", res);
      if (res.ok) {
        res.json().then((data) => console.log(data));
      } else {
        console.log(res);
        alert("Error processing image.");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      alert("An error ocurred while processing the image");
    });
}

export async function UpdateStatus(userId, jobId, status) {
  const token = getToken()
  console.log("UpdateStatus called with:", { userId, jobId, status });
  try {
    const response = await fetch(DOMAIN + UPDATE_STATUS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token && { "Authorization": `Bearer ${token}` }),
      },
      body: JSON.stringify({
        user_id: userId,
        job_id: jobId,
        status: status,
      }),
    });

    console.log("Response status:", response.status);
    console.log("Response statusText:", response.statusText);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
}

export async function signupFD(formData) {
  try {
    const response = await fetch(DOMAIN + SIGNUP, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Response data: ", data);
      return data;
    } else {
      const errorData = await response.json();
      console.log("Error response: ", errorData);
      throw new Error(errorData.message || "Error signing up");
    }
  } catch (error) {
    console.error("Error in signupFD:", error);
    throw error;
  }
}

// export async function editProfileFD(formData) {
//   try {
//     const token = getToken();
//     const response = await fetch(DOMAIN + EDIT_PROFILE, {
//       method: "POST",
//       headers:{
//         ...(token && { "Authorization": `Bearer ${token}` }),
//       },
//       body: formData,
//     });

//     if (response.ok) {
//       const data = await response.json();
//       console.log("Response data: ", data);
//       return data;
//     } else {
//       const errorData = await response.json();
//       console.log("Error response: ", errorData);
//       throw new Error(errorData.message || "Error editing profile.");
//     }
//   } catch (error) {
//     console.error("Error in editing profile:", error);
//     throw error;
//   }
// }


export async function updateProfile(formData) {
  const token = getToken();
  const response = await fetch(DOMAIN + EDIT_PROFILE, {
    method: 'POST',
    headers: {
      ...(token && { 'Authorization': `Bearer ${token}` }),
    },
    body: formData,
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.error || 'Failed to update profile');
  }

  const data = await response.json();
  return data;
}

// export async function fetchProfile() {
//   const token = getToken();
//   const response = await fetch(DOMAIN + '/get_profile', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       ...(token && { 'Authorization': `Bearer ${token}` }),
//     },
//   });

//   if (!response.ok) {
//     const errorMessage = await response.json();
//     throw new Error(errorMessage.error || 'Failed to fetch profile data');
//   }

//   const data = await response.json();
//   return data;
// }
