import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, get_skills } from '../../../utilities/apis';
import Loader from '../../Loader';
import { setProfilePhoto as setReduxProfilePhoto, setProfileData } from '../../../app/state/userSlice';
import company from '../../../assets/companyLogo.png';
import defaultProfilePic from '../../../assets/defaultProfilePic.png';  // Default profile picture
import NavBar from '../../Navbar';

function Profile() {
  const [localProfilePhoto, setLocalProfilePhoto] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { profileData, profilePhoto, profilePhotoContentType, userID, userRole } = useSelector(state => ({
    profileData: state.user.profileData,
    profilePhoto: state.user.profilePhoto,
    profilePhotoContentType: state.user.profilePhotoContentType,
    userID: state.user.userID,
    userRole: state.user.userRole
  }));

  const [formData, setFormData] = useState({
    name: '',
    emailId: '',
    mobNo: '',
    current_company: '',
    companyName: '',
    skills: '',
    preferredLocation: '',
    companywebsite: '',
    experience: '',
    ctc: '',
    notice_period: '',
    education: '',
    designation: '',
    company_logo_content: null,
    company_logo_content_type: ''
  });

  useEffect(() => {
    if (userID) {
      fetchProfileData();
    }
  }, [userID]);

  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await get_skills({ user_id: userID });
      console.log('Profile Data Response:', response);
      if (response.success) {
        dispatch(setProfileData(response));
        const skills = typeof response.req_skills === 'string' 
          ? response.req_skills 
          : Array.isArray(response.req_skills) 
          ? response.req_skills.join(', ') 
          : '';
        console.log('Skills:', skills);
        setFormData({
          name: response.person_name || '',
          emailId: response.email_id || '',
          mobNo: response.mobNo || '',
          current_company: response.current_company_name || '',
          companyName: response.company_name || '',
          skills: skills,
          preferredLocation: response.location || '',
          companywebsite: response.company_website || '',
          experience: response.experience || '',
          ctc: response.ctc || '',
          notice_period: response.notice_period || '',
          education: response.education || '',
          designation: response.designation || '',
          company_logo_content: response.company_logo_content || null,
          company_logo_content_type: response.company_logo_content_type || ''
        });

        // Check if profile photo exists, else set to null or default
        if (response.profile_photo_content && response.profile_photo_content_type) {
          dispatch(setReduxProfilePhoto({
            photo: response.profile_photo_content,
            contentType: response.profile_photo_content_type,
          }));
        } else {
          dispatch(setReduxProfilePhoto({
            photo: null,
            contentType: null,
          }));
        }
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
      alert('Error fetching profile data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }, [formData]);

  const handleFileChange = useCallback((e) => {
    const { name, files } = e.target;
    if (name === 'profile_photo') {
      setLocalProfilePhoto(files[0]);
    } else if (name === 'company_logo') {
      setCompanyLogo(files[0]);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const updatedFormData = new FormData();
    updatedFormData.append('user_id', userID);
    Object.keys(formData).forEach(key => updatedFormData.append(key, formData[key]));
    if (localProfilePhoto) {
      updatedFormData.append('profile_photo', localProfilePhoto);
    }
    if (companyLogo) {
      updatedFormData.append('company_logo', companyLogo);
    }

    try {
      const response = await updateProfile(updatedFormData);
      alert(response.message);

      if (localProfilePhoto) {
        const reader = new FileReader();
        reader.onloadend = () => {
          dispatch(setReduxProfilePhoto({
            photo: reader.result.split(',')[1], 
            contentType: localProfilePhoto.type,
          }));
        };
        reader.readAsDataURL(localProfilePhoto);
      }

      if (companyLogo) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData(prevState => ({
            ...prevState,
            company_logo_content: reader.result.split(',')[1],
            company_logo_content_type: companyLogo.type,
          }));
        };
        reader.readAsDataURL(companyLogo);
      }

      fetchProfileData();

    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <NavBar logOutFlag={true} />
    <div className="flex justify-center items-center min-h-screen bg-gray-100 py-6">
      <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-700">Update Profile</h2>
        {userRole === 'employee' ? (
          profilePhoto ? (
            <div className="flex justify-center mb-6">
              <img
                src={`data:${profilePhotoContentType};base64,${profilePhoto}`}
                alt="Profile"
                className="w-32 h-32 rounded-full border-2 border-gray-300"
              />
            </div>
          ) : (
            <div className="flex justify-center mb-6">
              <img
                src={defaultProfilePic}  // Default profile picture for employee
                alt="Default Profile"
                className="w-32 h-32 rounded-full border-2 border-gray-300"
              />
            </div>
          )
        ) : (
          formData.company_logo_content ? (
            <div className="flex justify-center mb-6">
              <img
                src={`data:${formData.company_logo_content_type};base64,${formData.company_logo_content}`}
                alt="Company Logo"
                className="w-32 h-32 rounded-full border-2 border-gray-300"
              />
            </div>
          ) : (
            <div className="flex justify-center mb-6">
              <img
                src={company}
                alt="Default Company Logo"
                className="w-32 h-32 rounded-full border-1 border-gray-200"
              />
            </div>
          )
        )}
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="form-group">
            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
            />
          </div>
          <div className="form-group">
            <label htmlFor="emailId" className="block text-gray-700 font-medium mb-2">Email ID</label>
            <input
              type="email"
              name="emailId"
              value={formData.emailId}
              readOnly
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
            />
          </div>
          {userRole === 'employee' && (
            <>
              <div className="form-group">
                <label htmlFor="mobNo" className="block text-gray-700 font-medium mb-2">Mobile Number</label>
                <input
                  type="text"
                  name="mobNo"
                  value={formData.mobNo}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="current_company" className="block text-gray-700 font-medium mb-2">Current Company</label>
                <input
                  type="text"
                  name="current_company"
                  value={formData.current_company}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="skills" className="block text-gray-700 font-medium mb-2">Skills</label>
                <input
                  type="text"
                  name="skills"
                  value={formData.skills}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="preferredLocation" className="block text-gray-700 font-medium mb-2">Preferred Location</label>
                <input
                  type="text"
                  name="preferredLocation"
                  value={formData.preferredLocation}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="experience" className="block text-gray-700 font-medium mb-2">Experience</label>
                <input
                  type="text"
                  name="experience"
                  value={formData.experience}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="ctc" className="block text-gray-700 font-medium mb-2">CTC</label>
                <input
                  type="text"
                  name="ctc"
                  value={formData.ctc}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="notice_period" className="block text-gray-700 font-medium mb-2">Notice Period</label>
                <input
                  type="text"
                  name="notice_period"
                  value={formData.notice_period}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="education" className="block text-gray-700 font-medium mb-2">Education</label>
                <input
                  type="text"
                  name="education"
                  value={formData.education}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="designation" className="block text-gray-700 font-medium mb-2">Designation</label>
                <input
                  type="text"
                  name="designation"
                  value={formData.designation}
                  onChange={handleInputChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label htmlFor="profile_photo" className="block text-gray-700 font-medium mb-2">Profile Photo</label>
                <input
                  type="file"
                  name="profile_photo"
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                />
              </div>
            </>
          )}
          {userRole === 'employer' && (
            <>
              <div className="form-group">
                <label htmlFor="companyName" className="block text-gray-700 font-medium mb-2">Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  readOnly
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group">
                <label htmlFor="companywebsite" className="block text-gray-700 font-medium mb-2">Company Website</label>
                <input
                  type="text"
                  name="companywebsite"
                  value={formData.companywebsite}
                  readOnly
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring focus:ring-blue-200 focus:outline-none p-2"
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label htmlFor="company_logo" className="block text-gray-700 font-medium mb-2">Company Logo</label>
                <input
                  type="file"
                  name="company_logo"
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                />
              </div>
            </>
          )}
          <div className="col-span-1 md:col-span-2 relative">
            <button
              type="submit"
              className="w-full h-8 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
              disabled={isLoading}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                {isLoading ? <Loader /> : 'Update Profile'}
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}

export default React.memo(Profile);
