import NavBar from "../../Navbar";
import { useEffect, useState } from "react";
import { createJob, edit_job } from "../../../utilities/apis";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalPg from "../../ModalPg/ModalPg";
import JobPreviewModal from "../../JobPreviewModal";
import { Alert, Slide } from "@mui/material";
import Loader from "../../Loader";

const PostJobEx = () => {
  const selectUserID = useSelector((state) => state.user.userID);
  const selectUserRole = useSelector((state) => state.user.userRole);
  const jobs = useSelector((state) => state.user.jobArray);
  const jobId = useSelector((state) => state.user.editJobId);
  const isEdit = useSelector((state) => state.user.forEdit);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false); // New state for handling form reset

  const initialState = {
    user_id: selectUserID,
    job_title: "",
    job_description: "",
    min_salary_compensation: "",
    max_salary_compensation: "",
    location: "",
    benefits: "",
    preferred_qualification: "",
    company_information: "",
    deadline: "",
    work_schedule: "",
    industry: "",
    company_logo: null,
    min_experience: "",
    max_experience: "",
    req_skills: "",
  };

  const navigate = useNavigate();
  const [userState, setUserState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [sendAlert, setSendAlert] = useState(false);

  useEffect(() => {
    console.log(userState);
  }, [userState]);

  const validateFields = () => {
    let isValid = true;
    const newErrors = {};

    if (!userState.job_title.trim()) {
      newErrors.job_title = "Job Title is required";
      isValid = false;
    }
    if (!userState.job_description.trim()) {
      newErrors.job_description = "Job Description is required";
      isValid = false;
    }
    if (!userState.location.trim()) {
      newErrors.location = "Location is required";
      isValid = false;
    }
    if (!userState.req_skills.trim()) {
      newErrors.req_skills = "Skills are required";
      isValid = false;
    }

    if (!userState.min_experience.trim()) {
      newErrors.min_experience = "Minimum Experience is required";
      isValid = false;
    }
    if (!userState.max_experience.trim()) {
      newErrors.max_experience = "Maximum Experience is required";
      isValid = false;
    }
    if (!userState.min_salary_compensation.trim()) {
      newErrors.min_salary_compensation = "Minimum Salary is required";
      isValid = false;
    }
    if (!userState.max_salary_compensation.trim()) {
      newErrors.max_salary_compensation = "Maximum Salary is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    if (isEdit) {
      try {
        const jobToEdit = jobs.find((job) => job.job_id === jobId);
        setUserState((prevState) => ({
          ...prevState,
          ...jobToEdit,
        }));
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setUserState(initialState);
    }
  }, [isEdit, jobId, jobs]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleFile = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setUserState((prev) => ({
        ...prev,
        company_logo: selectedFile,
      }));
    } else {
      setUserState((prev) => ({
        ...prev,
        company_logo: null,
      }));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSendAlert(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [sendAlert]);

  const handleCreateEditJob = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      const formData = new FormData();
      Object.keys(userState).forEach((key) => {
        formData.append(key, userState[key]);
      });
      formData.append("jobId", jobId);

      if (isEdit) {
        try {
          const editRes = await edit_job(formData);
          if (editRes.success) {
            setSendAlert(true);
            setIsLoading(true);
            setTimeout(() => {
              navigate("/home");
            }, 1000);
          }
        } catch (error) {
          console.error("Error updating job:", error);
        }
      } else {
        setIsModalOpen(true);
        try {
          await createJob(formData);
          setUserState(initialState);
          setErrors({});
        } catch (error) {
          console.error("Error creating job:", error);
        }
      }
    }
  };

  const handlePreview = () => {
    if (validateFields()) {
      setIsPreviewOpen(true);
    }
  };

  const handleNoClk = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  const handleYesClk = () => {
    setIsModalOpen(false);
    setIsResetting(true);  // Start the resetting process with a loader
    setTimeout(() => {
      setUserState(initialState);  // Reset form fields
      setErrors({});
      setIsResetting(false);  // Stop the loader after resetting
    }, 500);  // Adjust the time if needed to match the actual reset delay
  };

  return (
    <div className="min-h-screen">
      <ModalPg
        handleNoClk={handleNoClk}
        handleYesClk={handleYesClk}
        isOpen={isModalOpen}
        title="Job created successfully!"
        description="Do you want to add more jobs?"
      />
      <JobPreviewModal
        isOpen={isPreviewOpen}
        handleClose={() => setIsPreviewOpen(false)}
        jobDetails={userState}
      />
      <NavBar logOutFlag={true} />
      <div className="flex justify-center items-center pt-[5vh]">
        <div className="bg-white max-w-4xl w-full rounded-lg shadow-lg p-14">
          {isResetting ? (
            <div className="flex justify-center items-center">
              <Loader />
              <span className="ml-2 text-gray-500">Please wait while the form resets...</span>
            </div>
          ) : (
            <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Job Title */}
              <div>
                <label
                  htmlFor="inputJobTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Job Title*
                </label>
                <input
                  type="text"
                  name="job_title"
                  value={userState.job_title}
                  className={`form-control ${errors.job_title ? "border-red-500" : ""
                    }`}
                  id="inputJobTitle"
                  onChange={handleChange}
                />
                {errors.job_title && (
                  <div className="text-red-500 text-sm">{errors.job_title}</div>
                )}
              </div>
              {/* Salary Range */}
              <div>
                <label
                  htmlFor="inputSalaryCompensation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Salary Range <em className="text-gray-500">(Annual)</em>
                </label>
                <div className="flex">
                  <div className="currency px-2">
                    <select
                      id="currency"
                      name="currency"
                      value={userState.currency}
                      onChange={handleChange}
                      className="h-full rounded-md border bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    >
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="SAR">SAR</option>
                      <option value="AED">AED</option>
                      <option value="UAR">UAR</option>
                      <option value="AUD">AUD</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="min_salary_compensation"
                    placeholder="Min"
                    value={userState.min_salary_compensation}
                    className={`form-control mr-2 ${errors.min_salary_compensation ? "border-red-500" : ""
                      }`}
                    id="inputMinSalaryCompensation"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="max_salary_compensation"
                    placeholder="Max"
                    value={userState.max_salary_compensation}
                    className={`form-control ${errors.max_salary_compensation ? "border-red-500" : ""
                      }`}
                    id="inputMaxSalaryCompensation"
                    onChange={handleChange}
                  />
                </div>
                {errors.min_salary_compensation && (
                  <div className="text-red-500 text-sm">
                    {errors.min_salary_compensation}
                  </div>
                )}
                {errors.max_salary_compensation && (
                  <div className="text-red-500 text-sm">
                    {errors.max_salary_compensation}
                  </div>
                )}
              </div>
              {/* Benefits */}
              <div>
                <label
                  htmlFor="inputBenefits"
                  className="block text-sm font-medium text-gray-700"
                >
                  Benefits
                </label>
                <input
                  type="text"
                  name="benefits"
                  value={userState.benefits}
                  className={`form-control ${errors.benefits ? "border-red-500" : ""
                    }`}
                  id="inputBenefits"
                  onChange={handleChange}
                />
                {errors.benefits && (
                  <div className="text-red-500 text-sm">{errors.benefits}</div>
                )}
              </div>
              {/* Preferred Qualification */}
              <div>
                <label
                  htmlFor="inputPreferredQualification"
                  className="block text-sm font-medium text-gray-700"
                >
                  Preferred Qualification
                </label>
                <input
                  type="text"
                  name="preferred_qualification"
                  value={userState.preferred_qualification}
                  className="form-control"
                  id="inputPreferredQualification"
                  onChange={handleChange}
                />
              </div>
              {/* Experience */}
              <div>
                <label
                  htmlFor="inputExperience"
                  className="block text-sm font-medium text-gray-700"
                >
                  Required Experience (years)*
                </label>
                <div className="flex">
                  <input
                    type="text"
                    name="min_experience"
                    placeholder="Min"
                    value={userState.min_experience}
                    className={`form-control mr-2 ${errors.min_experience ? "border-red-500" : ""
                      }`}
                    id="inputMinExperience"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="max_experience"
                    placeholder="Max"
                    value={userState.max_experience}
                    className={`form-control ${errors.max_experience ? "border-red-500" : ""
                      }`}
                    id="inputMaxExperience"
                    onChange={handleChange}
                  />
                </div>
                {errors.min_experience && (
                  <div className="text-red-500 text-sm">
                    {errors.min_experience}
                  </div>
                )}
                {errors.max_experience && (
                  <div className="text-red-500 text-sm">
                    {errors.max_experience}
                  </div>
                )}
              </div>
              {/* Industry */}
              <div>
                <label
                  htmlFor="inputContactInformation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Industry
                </label>
                <input
                  type="text"
                  name="industry"
                  value={userState.industry}
                  className={`form-control ${errors.industry ? "border-red-500" : ""
                    }`}
                  id="inputContactInformation"
                  onChange={handleChange}
                />
                {errors.industry && (
                  <div className="text-red-500 text-sm">{errors.industry}</div>
                )}
              </div>
              {/* Job Description */}
              <div>
                <label
                  htmlFor="inputJobDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  Job Description*
                </label>
                <textarea
                  name="job_description"
                  value={userState.job_description}
                  className={`form-control ${errors.job_description ? "border-red-500" : ""
                    }`}
                  id="inputJobDescription"
                  onChange={handleChange}
                  rows="4"
                ></textarea>
                {errors.job_description && (
                  <div className="text-red-500 text-sm">
                    {errors.job_description}
                  </div>
                )}
              </div>
              {/* Required Skills */}
              <div>
                <label
                  htmlFor="inputReqSkills"
                  className="block text-sm font-medium text-gray-700"
                >
                  Required Skills*
                </label>
                <textarea
                  name="req_skills"
                  value={userState.req_skills}
                  className={`form-control ${errors.req_skills ? "border-red-500" : ""
                    }`}
                  id="inputReqSkills"
                  onChange={handleChange}
                  rows="4"
                ></textarea>
                {errors.req_skills && (
                  <div className="text-red-500 text-sm">{errors.req_skills}</div>
                )}
              </div>
              {/* Location */}
              <div>
                <label
                  htmlFor="inputLocation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location*
                </label>
                <input
                  type="text"
                  name="location"
                  value={userState.location}
                  className={`form-control ${errors.location ? "border-red-500" : ""
                    }`}
                  id="inputLocation"
                  onChange={handleChange}
                />
                {errors.location && (
                  <div className="text-red-500 text-sm">{errors.location}</div>
                )}
              </div>
              {/* Work Mode */}
              <div className="col-span-2 flex flex-col md:flex-row justify-between items-center">
                <div className="flex flex-col items-center w-full md:w-1/2">
                  <label htmlFor="workMode" className="form-label">
                    Work Mode:
                  </label>
                  <div className="flex space-x-4 mt-2">
                    <input
                      type="radio"
                      name="work_mode"
                      value="Remote"
                      id="workModeRemote"
                      onChange={handleChange}
                    />
                    <label htmlFor="workModeRemote" className="ml-2">
                      Remote
                    </label>

                    <input
                      type="radio"
                      name="work_mode"
                      value="Onsite"
                      id="workModeOnsite"
                      onChange={handleChange}
                    />
                    <label htmlFor="workModeOnsite" className="ml-2">
                      Onsite
                    </label>
                    <input
                      type="radio"
                      name="work_mode"
                      value="Hybrid"
                      id="workModeRemote"
                      onChange={handleChange}
                    />
                    <label htmlFor="workModeRemote" className="ml-2">
                      Hybrid
                    </label>
                  </div>
                </div>
                {/* Work Schedule */}
                <div className="flex flex-col items-center w-full md:w-1/2 md:mt-0">
                  <label htmlFor="workSchedule" className="form-label">
                    Work Type:
                  </label>
                  <div className="flex space-x-4 mt-2">
                    <input
                      type="radio"
                      name="work_schedule"
                      value="Full Time"
                      id="workScheduleFullTime"
                      onChange={handleChange}
                    />
                    <label htmlFor="workScheduleFullTime" className="ml-2">
                      Full Time
                    </label>

                    <input
                      type="radio"
                      name="work_schedule"
                      value="Part Time"
                      id="workSchedulePartTime"
                      onChange={handleChange}
                    />
                    <label htmlFor="workSchedulePartTime" className="ml-2">
                      Part Time
                    </label>

                    <input
                      type="radio"
                      name="work_schedule"
                      value="Consultant"
                      id="workScheduleConsultant"
                      onChange={handleChange}
                    />
                    <label htmlFor="workScheduleConsultant" className="ml-2">
                      Consultant
                    </label>
                  </div>
                </div>
              </div>
              {/* Submit Buttons */}
              <div className="col-span-2 flex justify-between mt-6">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handlePreview}
                >
                  Preview
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateEditJob}
                >
                  {isEdit ? "Update" : "Create"}
                </button>
              </div>
            </form>
          )}
          <div className="w-full flex justify-center items-center position: relative;">
            {isLoading && (
              <div className="flex justify-center items-center position: absolute ">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostJobEx;
