export const DOMAIN = `https://api.hiremenext.work`;
export const GENERATE_OTP = `/generate_otp`;
export const VALIDATE_OTP = `/verify_otp`;
export const AUTO_LOGIN = `/auto_login`;
export const SIGNUP = `/signup`;
export const CREATEJOB = `/create_job_post`;
export const UPLOAD_RESUME = `/upload_resume`;
export const LIST_JOB = `/list_jobs`;
export const SEARCH_JOB = `/search_jobs`;
export const EDIT_JOB = `/edit_job_post`;
export const GET_USER_SKILLS = `/get_skills`;
export const DELETE_JOB = `/delete_jobs`;
export const APPLY_JOB = `/apply_job`;
export const VIEW_APPLICATIONS = `/view_applications`;
export const DOWNLOAD_RESUME = `/download_resume`;
export const UPDATE_STATUS = '/update_status';
export const VIEW_USERS_BY_STATUS = '/view_users_by_status';
// export const UPDATE_PROFILE = '/update_profile';
export const EDIT_PROFILE = `/edit_profile`;


