import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JobCard from "../../Jobcard/JobCard"
import NavBar from "../../Navbar";
import { useSelector, useDispatch } from "react-redux";
import { get_skills, list_jobs, search_jobs, del_jobs, apply_job } from "../../../utilities/apis";
import { setJobArray, setUserName, setUserVerified } from "../../../app/state/userSlice";
import ModalPg from "../../ModalPg/ModalPg";
import Loader from "../../Loader";
import { setProfileDetails } from "../../../app/state/profileSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const [jobList, setJobLists] = useState([]);
  const [searchQuery, setSearchQuery] = useState({ searchQuery: "" });
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const [personName, setPersonName] = useState("");

  const selectUserRole = useSelector((state) => state.user.userRole);
  const selectUserID = useSelector((state) => state.user.userID);
  const isEmployer = useSelector((state) => state.user.userRole) === "employer";
  const isAdmin = useSelector((state) => state.user.userRole) === "admin";
  const userVerified = useSelector((state) => state.user.userVerified);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appliedJob, setApplyJob] = useState("");
  const [applyJobId, setApplyJobId] = useState(null);

  useEffect(() => {
    callJobAPI();
  }, []);

  useEffect(() => {
    console.log("Jobs Updated");
    if (isAdmin) {
      navigate("/admin-dashboard")
    }
  }, [jobList]);

  const callJobAPI = async () => {
    setIsLoading(true);
    try {
      const getSkillsRes = await get_skills({
        user_id: selectUserID,
      });

      dispatch(setProfileDetails(getSkillsRes));
      dispatch(setUserName(getSkillsRes.person_name));
      setPersonName(getSkillsRes.person_name);

      if (getSkillsRes.success && !isEmployer) {
        const searchJobString = getSkillsRes.req_skills.map((skill) => skill.trim()).join(",");
        setSearchQuery({ searchQuery: searchJobString });

        const jobFromSkillsRes = await search_jobs({
          user_id: selectUserID,
          user_role: selectUserRole,
          keywords: searchJobString,
        });
        setJobLists(jobFromSkillsRes);
        dispatch(setJobArray(jobFromSkillsRes));
      } else {
        const jobRes = await list_jobs({
          user_role: selectUserRole,
          user_id: selectUserID,
        });
        setJobLists(jobRes);
        dispatch(setJobArray(jobRes));
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
      try {
        const jobRes = await list_jobs({
          user_role: selectUserRole,
          user_id: selectUserID,
        });
        setJobLists(jobRes);
        dispatch(setJobArray(jobRes));
      } catch (error) {
        alert(error);
      }
    }
    setIsLoading(false);
  };

  const applyForJob = async (job_id) => {
    try {
      const applyRes = await apply_job({ user_id: selectUserID, job_id: job_id });
      if (applyRes.success) {
        handleApplyState(job_id);
      }
    } catch (error) {
      console.log("Error while applying for job: ", error);
    }
  };

  const handleNoClk = () => {
    setIsModalOpen(false);
  };

  const handleYesClk = () => {
    setIsModalOpen(false);
    applyForJob(applyJobId);
  };

  const handleSearch = (e) => {
    setSearchQuery((prevState) => ({
      ...prevState,
      searchQuery: e.target.value,
    }));
  };

  const handleApplyState = (jobId) => {
    const updatedJobs = jobList.map((job) =>
      job.job_id === jobId ? { ...job, applied_status: true } : job
    );
    setJobLists(updatedJobs);
  };

  const setJobTitle = (jobTitle, jobId) => {
    setApplyJob(jobTitle);
    setApplyJobId(jobId);
    setIsModalOpen(true);
  };

  const onSearchClick = async (e) => {
    e.preventDefault();
    try {
      if (searchQuery.searchQuery.length > 2) {
        setIsLoading(true);
        const searchRes = await search_jobs({
          user_id: selectUserID,
          user_role: selectUserRole,
          keywords: searchQuery.searchQuery,
        });
        setJobLists(searchRes);
        dispatch(setJobArray(searchRes));
        setIsLoading(false);
      } else {
        alert("Enter at least 3 characters.");
      }
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  const deleteJobAndUpdateState = async (jobId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this job?");
    if (confirmDelete) {
      try {
        const deleteRes = await del_jobs(jobId, { user_id: selectUserID });
        if (!deleteRes) {
          alert("Failed to delete job. No response from server.");
          return;
        }
        if (deleteRes.success && deleteRes.remaining_jobs) {
          setJobLists(deleteRes.remaining_jobs);
        } else {
          alert("Failed to delete job. Please try again.");
        }
      } catch (error) {
        alert("An error occurred while deleting the job. Please check your network connection and try again.");
      }
    }
  };

  const searchJobs = jobList.map((job, index) => (
    <JobCard
      key={index}
      jobDetails={job}
      onDelete={() => deleteJobAndUpdateState(job.job_id)}
      setJobTitle={setJobTitle}
    />
  ));

  return (
    <div className="bg-gray-100 min-h-screen">
      <NavBar postJobFlag={isEmployer && userVerified} logOutFlag={true} username={personName} />
      <div>
        <ModalPg
          isOpen={isModalOpen}
          handleNoClk={handleNoClk}
          handleYesClk={handleYesClk}
          description={`Do you want to Apply for ${appliedJob}?`}

        />
      </div>
      <section className="container  p-1">
        <section className="flex flex-col items-center justify-center h-52">
          <div className="text-center pt-1 mt-1">
            <h2 className="font-semibold text-gray-700 text-2xl">
              {isEmployer ? "Employer" : "Find Your Dream"}{" "}
              <span className="text-primary">{isEmployer ? "Dashboard" : "Job"}</span> {isEmployer ? " " : "Now"}
              <p className="text-gray-600 text-base">
                {isEmployer
                  ? "Find the perfect solution for all your hiring needs effortlessly."
                  : "Browse open jobs in different job categories"}
              </p>
            </h2>
          </div>
          <form className="flex items-center w-3/5 px-5 mt-3 text-blue-500 justify-between rounded-full bg-white shadow-md 	position: relative;" role="search">
            <input
              className=" p-3 text-sm w-[50%] border-none rounded-l-full text-black focus:outline-none"
              type="text"
              placeholder="search here"
              aria-label="Search"
              onChange={handleSearch}
              disabled={isEmployer && !userVerified}
            />
            <button onClick={onSearchClick} className="w-28  text-xl text-white end-0 bg-primary rounded-full position: absolute; " type="submit" disabled={isEmployer && !userVerified}>
              Search
            </button>
          </form>
          {isEmployer && !userVerified && (
            <div className="mt-3 text-center">
              <h1 className="text-xl text-red-600">Verification Pending</h1>
            </div>
          )}
        </section>
        <div className="flex flex-wrap gap-4 justify-center items-start  mt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center items-start">
            {isLoading ? <Loader /> : searchJobs}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
