import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileDetails: null,
  },
  reducers: {
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfileDetails } = profileSlice.actions;

export default profileSlice.reducer;

//setAuthToken is the action here
//the exported reducer should be registered in the store file with a name(authReducer).
