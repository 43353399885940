import leuwint from "../../images/leuwint.jpeg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { autoLoginViaToken, generateOTP, validateOTP } from "../../../utilities/apis";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import Slide from "@mui/material/Slide";
import { setUserID, setUserRole, setUserVerified } from "../../../app/state/userSlice";
import Loader from "../../Loader";
import { setAuthToken } from "../../../app/state/authSlice";

function Login() {
  const [userName, setUserName] = useState("");
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showRequestAgain, setShowRequestAgain] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [resend, setResend] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [otpValidate, setOtpValidate] = useState(false);
  const [showOtpTostr, setShowOtpTostr] = useState(false);
  const [timer, setTimer] = useState(0);
  const [resendText, setResendText] = useState(null);
  const [darkMode, setDarkMode] = useState(false); // Set dark mode as default
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserName = (e) => {
    const value = e.target.value;
    setUserName(value);
    if (!value.trim()) {
      setEmailError("");
    } else if (
      !value.match(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/)
    ) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    autoLogin();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOtpValidate(false);
      setShowOtpTostr(false);
    }, 3000);
  }, [showOtpTostr, otpValidate]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResend(false);
      setResendText(null);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const autoLogin = async () => {
    try {
      const token = await localStorage.getItem('access_token');
      dispatch(setAuthToken(token));
      if (token) {
        const autoLoginRes = await autoLoginViaToken(token);
        if (autoLoginRes.verified) {
          navigate("/home");
        } else {
          console.error("Login not verified");
        }
      } else {
        console.error("No token found in local storage");
      }
    } catch (error) {
      console.error("Error during auto-login:", error);
    }
  };

  const handleOtp = (e) => {
    const value = e.target.value;
    setOtp(value);
    if (!value.trim()) {
      setOtpError("");
    } else if (!value.match(/^\d{4}$/)) {
      setOtpError("Please enter a 4-digit OTP.");
    } else {
      setOtpError("");
    }
  };

  const handleSendOTP = async () => {
    if (!userName.match(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setIsLoading(true); // Start loading
    try {
      const apiResponse = await generateOTP(userName);
      if (apiResponse.success) {
        setResend(true);
        setTimer(30); // Reset timer to 30 seconds
        setDisableSubmit(false);
        setResponseMessage(apiResponse.message);
        setShowOtpTostr(true);
      } else {
        setResponseMessage(apiResponse.message);
        setShowRequestAgain(true);
      }
      setIsUserRegistered(apiResponse.registered);
    } catch (error) {
      console.log("Unable to generate OTP: ", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const OTPLogin = async () => {
    try {
      const verifyOtpRes = await validateOTP(otp, userName);
      if (verifyOtpRes.success) {
        localStorage.setItem('access_token', verifyOtpRes.token);
        dispatch(setUserID(userName));
        dispatch(setUserRole(verifyOtpRes.user_role));
        dispatch(setAuthToken(verifyOtpRes.token));
        dispatch(setUserVerified(verifyOtpRes.verified));

        // Navigate user to Dashboard
        navigate(isUserRegistered ? "/home" : "/signup");
        console.log("OTP Matched");
      } else {
        setOtpValidate(true);
        console.log("Entered OTP is wrong");
      }
    } catch (error) {
      console.error("Error during OTP validation:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userName) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    if (!otp.match(/^\d{4}$/)) {
      setOtpError("Please enter a 4-digit OTP.");
      return;
    }
    try {
      await OTPLogin();
      console.log("Form submitted!");
    } catch (error) {
      console.error("Error generating OTP", error);
      alert("Error generating OTP. Enter a valid email ID");
    }
  };

  return (
    <>
      <div className="relative flex justify-center">
        <div className="absolute">
          <div className="otp_alerts">
            <Slide in={otpValidate}>
              <Alert severity="warning">Wrong OTP entered!</Alert>
            </Slide>
          </div>
          <div className="otp_alerts">
            <Slide in={showOtpTostr}>
              <Alert severity="success">{responseMessage}</Alert>
            </Slide>
          </div>
        </div>
      </div>

      <section className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
        <div className="flex flex-col md:flex-row bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden w-full max-w-4xl">
          <div className="w-full h-[60vh] md:w-1/2 p-8 flex flex-col justify-between">
            <div>
              <h2 className="text-2xl mt-6 font-semibold text-gray-800 dark:text-white mb-6">Login</h2>
              <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div>
                  <label className="block text-gray-800 dark:text-gray-300">Email ID</label>
                  <div className="flex mt-1">
                    <input
                      type="email"
                      className="w-full px-3 py-2 border rounded-l-lg shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                      onChange={handleUserName}
                      value={userName}
                    />
                    <button
                      type="button"
                      className="w-52 border-l-0 text-sm rounded-r-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none"
                      onClick={handleSendOTP}
                      disabled={resend}
                    >
                      {resend ? `Resend OTP (${timer}s)` : "Send OTP"}
                    </button>
                  </div>
                  <small id="emailError" className="text-red-600">{emailError}</small>
                </div>
                <div>
                  <label className="block text-gray-800 dark:text-gray-300">OTP</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 mt-1 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                    onChange={handleOtp}
                    value={otp}
                    maxLength={4}
                  />
                  <small id="otpError" className="text-red-600">{otpError}</small>
                </div>
              </form>
              <div className="w-full flex justify-center items-center position: relative;">
                {isLoading && (
                  <div className="flex justify-center items-center mt-4 position: absolute ">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="py-2 px-20 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none"
                onClick={handleSubmit}
                disabled={disableSubmit}
              >
                Login
              </button>

            </div>
          </div>
          <div className="w-full md:w-1/2 bg-blue-600 p-8 flex items-center justify-center">
            <div>
              <h2 className="text-3xl font-semibold text-white mb-4">HireMeNext</h2>
              <p className="text-white mb-6">Connecting Talent with Opportunity: Your Ultimate Destination for Personalized Job and HR Recruiting Solutions.</p>
              <p className="text-white">Over <span className="font-semibold">15.7k</span> Happy Users</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
