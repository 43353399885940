import React from "react";
import "./JobCard.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setAplcntJobTitle,
  setApplications,
  setEditJobId,
  setForEdit,
  setJobToView,
} from "../../app/state/userSlice";
import { apply_job, view_applications } from "../../utilities/apis";
import { PiHandbagSimpleFill } from "react-icons/pi";
import { GiMoneyStack } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import leuwint_logo from "../images/leuwint_logo.jpg";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import logo from '../../assets/companyLogo.png'

const JobCard = ({ jobDetails, onDelete, setJobTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const skillsArray = jobDetails.req_skills.split(",");

  const handleClick = () => {
    console.log(jobDetails.job_id);
    onDelete(jobDetails.job_id);
  };

  const isEmployer = useSelector((state) => state.user.userRole) === "employer";
  const isEmployee = useSelector((state) => state.user.userRole) === "employee";

  const userName = useSelector((state) => state.user.userID);

  async function viewApplicants() {
    const applicants_res = await view_applications({
      job_id: jobDetails.job_id,
    });
    if (applicants_res.success) {
      dispatch(setApplications(applicants_res.applications));
    }
  }

  const handleView = () => {
    if (isEmployer) {
      viewApplicants();
      dispatch(setAplcntJobTitle(jobDetails.job_title));
      navigate("/view-applicants", { state: { job_id: jobDetails.job_id } });
    } else {
      navigate("/view-job");
    }
  };

  const handleApplyClick = async () => {
    try {
      setJobTitle(jobDetails.job_title, jobDetails.job_id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewJob = async() => {
    dispatch(setJobToView(jobDetails.job_id))
    navigate(`/view-applied/${jobDetails.job_id}`)
  }

  const skillsBadge = (
    <div className="flex flex-row items-center justify-center gap-[0.5em] p-[0.25em]">
      {skillsArray.slice(0, 5).map((skill, index) => (
        <React.Fragment key={index}>
          <p className="text-xs font-semibold text-gray-500">{skill}</p>
          {index < skillsArray.slice(0, 5).length - 1 && (
            <span className="h-[0.5em] w-[1px] rounded-full bg-gray-300"></span>
          )}
        </React.Fragment>
      ))}
      {skillsArray.length > 5 && (
        <>
          <span className="h-[0.5em] w-[1px] rounded-full text-gray-500"></span>
          <p className="text-xs font-semibold text-gray-500">+{skillsArray.length - 5}</p>
        </>
      )}
    </div>
  );

  const base64Image = jobDetails.company_logo_content_type && jobDetails.company_logo_content 
  ? `data:${jobDetails.company_logo_content_type};base64,${jobDetails.company_logo_content}`:null;

  return (
    <>
      <div className="weather flex min-h-[14em] max-h-[14em] min-w-[20em] flex-col items-center justify-center gap-[0.5em] rounded-[1.5em] bg-[#fefefe] px-[1em] py-[0.5em] font-nunito shadow-sm">
        <div className="flex h-fit w-full items-center justify-space-between gap-[5em] truncate">
          <div className="flex flex-col items-start w-full justify-center">
            <p className="text-lg flex-2 font-semibold">{jobDetails.job_title}</p>
            <div className="flex items-center justify-center gap-[0.125em]">
              <p className="text-sm font-medium text-gray-500">Leuwint Technologies</p>
            </div>
          </div>
          <div className="flex h-full w-[6rem] flex-1 flex-col items-center py-[0.25em] text-[0.625em]">
            <div className="group relative z-0 h-[58px] w-[58px]">
              <img
               src={ base64Image?base64Image:logo}
                className="duration-200 ease-linear group-hover:-translate-y-[8px]"
                alt="Logo"
                height="48"
                width="48"
              />
            </div>
          </div>
        </div>

        <div className="flex h-fit w-full items-center justify-between">
          <div className="flex h-fit w-full flex-col items-start justify-between text-[0.75em]">
            <div className="flex flex-row items-center justify-center gap-[0.5em] p-[0.25em]">
              <PiHandbagSimpleFill className="text-md text-gray-500" />
              <p className="text-xs font-semibold text-gray-500"> {jobDetails.min_experience}-{jobDetails.max_experience} years</p>
              <span className="h-[0.5em] w-[1px] rounded-full bg-gray-300"></span>
              <GiMoneyStack className="text-md text-gray-500" />
              <p className="text-xs font-semibold text-gray-500">
                {jobDetails.min_salary_compensation}-{jobDetails.max_salary_compensation} LPA
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-[0.5em] p-[0.25em]  w-full ">
              <FaLocationDot className="text-md text-gray-500" />
              <p className="text-xs truncate  font-semibold text-gray-500">{jobDetails.location}</p>
            </div>
            {skillsBadge}
          </div>
        </div>
        <div className="h-[0.5px] w-full rounded-full bg-[#7e797e8c]"></div>
        <div className="flex w-full items-center justify-between mt-1">
          <div>
            {isEmployer && (
              // <i onClick={handleClick} class="fa-solid fa-trash edtDlt"></i>
              <button onClick={handleClick} className="text-lg px-2"><MdDeleteOutline /></button>
            )}
            {isEmployer && (

              <Link to={`/postjob`}>
                <button onClick={() => {
                  dispatch(setEditJobId(jobDetails.job_id));
                  dispatch(setForEdit(true));
                }} className="text-base px-2"><FiEdit /></button>
              </Link>
            )}
          </div>
          <div className="gap-3 px-2">
            {isEmployer && (
              <button
                onClick={handleView}
                className="px-3 mx-2 py-2 text-primary font-semibold text-sm rounded-lg shadow-sm hover:text-light "
              >
                View Applications
              </button>
            )}




            {isEmployee && (
              <button
                onClick={handleApplyClick}
                disabled={jobDetails.applied_status}
                className="px-3 py-2 bg-primary btn  text-white font-semibold rounded-lg text-sm shadow-md hover:bg-blue-800 focus:outline-none"
              >
                {jobDetails.applied_status ? "Applied" : "Apply"}
              </button>
            )}
            <button
                onClick={handleViewJob}
                className="px-3 mx-2 py-2 text-primary font-semibold text-sm rounded-lg shadow-sm hover:text-light "
              >
                View Job
              </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCard;
