import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userID: null,
    userRole: null,
    userName: null,
    editJobId: null,
    forEdit: false,
    jobArray: null,
    applications: null,
    applicant_jobTitle:null,
    userVerified:false,
    jobToView:null,
    profileData: {}, // Add profileData to the state
    profilePhoto: null, // Add profilePhoto to the state
    profilePhotoContentType: null, // Add profilePhotoContentType to the state

  },
  reducers: {
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setEditJobId: (state, action) => {
      state.editJobId = action.payload;
    },
    setJobArray: (state, action) => {
      state.jobArray = action.payload;
    },
    setForEdit: (state, action) => {
      state.forEdit = action.payload;
    },
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setAplcntJobTitle: (state, action) => {
      state.applicant_jobTitle = action.payload;
    },
    setUserVerified: (state, action) => {
      state.userVerified = action.payload;
    },
    setProfilePhoto: (state, action) => {
      state.profilePhoto = action.payload.photo;
      state.profilePhotoContentType = action.payload.contentType;
    },
    setProfileData: (state, action) => { // Add setProfileData action
      state.profileData = action.payload;
    },
    setJobToView: (state,action)=>{
      state.jobToView = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserID,
  setUserRole,
  setEditJobId,
  setJobArray,
  setForEdit,
  setApplications,
  setUserName,
  setAplcntJobTitle,
  setUserVerified,
  setJobToView,
  setProfilePhoto,
  setProfileData, // Export the new action
} = userSlice.actions;

export default userSlice.reducer;
