import React from "react";
import { PiHandbagSimpleFill } from "react-icons/pi";
import { GiMoneyStack } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";

const JobPreviewModal = ({ isOpen, handleClose, jobDetails }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6">
        
        <h2 className="text-xl font-bold mb-4">Job Preview</h2>
        <div className="weather flex min-h-[14em] max-h-[14em] min-w-[20em] flex-col items-center justify-center gap-[0.5em] rounded-[1.5em] bg-[#fefefe] px-[1em] py-[0.5em] font-nunito shadow-sm">
          <div className="flex h-fit w-full items-center justify-space-between gap-[5em]">
            <div className="flex flex-col items-start justify-center">
              <p className="text-lg flex-2 font-semibold">{jobDetails.job_title}</p>
              <div className="flex items-center justify-center gap-[0.125em]">
                <p className="text-sm font-medium text-gray-500">Company Name - To be added</p>
              </div>
            </div>
            <div className="flex h-full w-[6rem] flex-1 flex-col items-center py-[0.25em] text-[0.625em]">
              <div className="group relative z-0 h-[58px] w-[58px]">
                {/* Placeholder for company logo if needed */}
              </div>
            </div>
          </div>

          <div className="flex h-fit w-full items-center justify-between">
            <div className="flex h-fit w-full flex-col items-start justify-between text-[0.75em]">
              <div className="flex flex-row items-center justify-center gap-[0.5em] p-[0.25em]">
                <PiHandbagSimpleFill className="text-md text-gray-500" />
                <p className="text-xs font-semibold text-gray-500">{jobDetails.min_experience} - {jobDetails.max_experience} years</p>
                <span className="h-[0.5em] w-[1px] rounded-full bg-gray-300"></span>
                <GiMoneyStack className="text-md text-gray-500" />
                <p className="text-xs font-semibold text-gray-500">
                  {jobDetails.min_salary_compensation}-{jobDetails.max_salary_compensation} LPA
                </p>
              </div>
              <div className="flex flex-row items-center justify-center gap-[0.5em] p-[0.25em]">
                <FaLocationDot className="text-md text-gray-500" />
                <p className="text-xs font-semibold text-gray-500">{jobDetails.location}</p>
              </div>
              <div className="flex flex-row items-center justify-center gap-[0.5em] p-[0.25em]">
                {jobDetails.req_skills.split(",").map((skill, index) => (
                  <React.Fragment key={index}>
                    <p className="text-xs font-semibold text-gray-500">{skill}</p>
                    {index < jobDetails.req_skills.split(",").length - 1 && (
                      <span className="h-[0.5em] w-[1px] rounded-full bg-gray-300"></span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="h-[0.5px] w-full rounded-full bg-[#7e797e8c]"></div>
          <div className="flex w-full items-center justify-between mt-1">
            {/* Additional content if needed */}
          </div>
        </div>
        <div className="flex justify-end">
          <button onClick={handleClose} className="text-white bg-primary px-4 py-2 rounded-lg mt-4">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPreviewModal;
