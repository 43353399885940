import leuwint_logo from "./images/leuwint_logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setForEdit, setUserID } from "../app/state/userSlice";
import { setAuthToken } from "../app/state/authSlice";
import { Link } from 'react-router-dom';

function NavBar({ postJobFlag, logOutFlag, username }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function logout() {
    dispatch(setUserID(null));
    dispatch(setAuthToken(null));
    localStorage.removeItem("access_token");
    navigate("/");
  }

  const postJobBtn = (
    <button
      className="btn btn-primary"
      onClick={() => {
        navigate("/postjob");
        dispatch(setForEdit(false));
      }}
      type="button"
    >
      Create a Job
    </button>
  );

  const logOutBtn = (
    <button
      className="btn btn-outline-primary mx-3"
      type="button"
      onClick={logout}
    >
      Log Out
    </button>
  );

  return (
    <>
      <nav className="bg-white shadow-sm py-3 position: absolute;">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <a className="flex items-center" href="#">
              <img
                onClick={() => {
                  navigate("/home");
                }}
                src={leuwint_logo}
                alt="Leuwint Logo"
                className="w-15 h-12"
              />
            </a>
            <button
              className="block lg:hidden px-2 py-1 text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
            <div className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-6">
              <ul className="flex flex-col lg:flex-row lg:space-x-6">
                <li className="nav-item">
                  <a
                    className="text-gray-700 hover:text-gray-900"
                    aria-current="page"
                    href="#"
                  >
                    Jobs
                  </a>
                </li>
              </ul>
              <div className="flex items-center space-x-4">
                {logOutFlag && logOutBtn}
                {postJobFlag && postJobBtn}
                <button
                  className="btn btn-outline"
                  type="button"
                >
                    <Link to="/profile">
                  <div className="flex items-center">
                
                    <FontAwesomeIcon icon={faUser} />
                    
                    <span className="ml-2">{username}</span>
                  </div>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
