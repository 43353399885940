import leuwint from "../../images/leuwint.jpeg";
import "./Signup.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signupFD, uploadResume } from "../../../utilities/apis";
import { useDispatch, useSelector } from "react-redux";
import { setUserRole } from "../../../app/state/userSlice";
import { setAuthToken } from "../../../app/state/authSlice";
import PhoneNumberInput from "../../PhoneNumberInput";
import Loader from "../../Loader";

function Signup() {
  const initialState = {
    emailId: "",
    name: "",
    mobNo: "",
    countryCode: "+91",
    companyName: "",
    companyLocation: "",
    skills: "",
    preferredLocation: [],
    companywebsite: "",
    userType: "employee",
    notice_period: "",
    ctc: "",
    education: "",
    experience: "", // New field
    designation: "", // New field
    current_company: "", // New field
  };

  const [file, setFile] = useState(null);
  const [userState, setUserState] = useState(initialState);
  const [emailError, setEmailError] = useState("");
  const [showEmployeeField, setShowEmployeeField] = useState(true);
  const [hideFields, setHideFields] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const selectUserEmail = useSelector((state) => state.user.userID);
  const [errors, setErrors] = useState({});
  const [darkMode, setDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateFields = () => {
    const newErrors = {};

    if (!userState.name) newErrors.name = "Name is required";
    if (!userState.mobNo) newErrors.mobNo = "Mobile Number is required";
    if (userState.userType === "employee") {
      if (!userState.skills) newErrors.skills = "Skills are required";
      if (!userState.preferredLocation) newErrors.preferredLocation = "Preferred Location is required";
      if (!userState.notice_period) newErrors.notice_period = "Notice Period is required";
    }
    if (userState.userType === "employer") {
      if (!userState.companyName) newErrors.companyName = "Company Name is required";
      if (!userState.companyLocation) newErrors.companyLocation = "Company Location is required";
      if (!userState.companywebsite) newErrors.companywebsite = "Company Website is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = ({ countryCode, mobNo }) => {
    const trimmedMobNo = mobNo.slice(-10);
    setUserState((prevState) => ({
      ...prevState,
      countryCode,
      mobNo: trimmedMobNo,
    }));
  };

  useEffect(() => {
    console.log(userState);
  }, [userState]);

  useEffect(() => {
    setShowEmployeeField(userState.userType === "employee");
    setHideFields(userState.userType === "employee");
    setUserState((prev) => ({
      ...prev,
      name: "",
      mobNo: "",
      countryCode: "+1",
      companyName: "",
      skills: "",
      companyLocation: "",
      companywebsite: "",
      preferredLocation: [],
      notice_period: "",
      ctc: "",
      education: "",
      experience: "", // New field
      designation: "", // New field
      current_company: "", // New field
    }));
    setFile(null);
  }, [userState.userType]);

  useEffect(() => {
    setUserState((prev) => ({
      ...prev,
      emailId: selectUserEmail,
    }));
  }, [selectUserEmail]);

  const handleFile = async (e) => {
    try {
      if (e) {
        const selectedFile = e.target.files[0];
        await setFile(selectedFile);
        if (!selectedFile) {
          console.log("No file selected.");
          return;
        }
      }
      const formData = new FormData();
      return formData;
    } catch (error) {
      alert(error);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("No file Selected");
      return;
    }
    setIsUploading(true);
    const fD = await handleFile();
    fD.append("resume", file, `${file.name}`);
    fD.append("user_id", selectUserEmail);
    setIsLoading(true);

    try {
      const resumeResponse = await uploadResume(fD);
      if (resumeResponse) {
        setIsUploading(false);
        setHideFields(false);
        const { person_name, contact_number, locations, skills } = resumeResponse[0];
        const preferredLocationn = locations.length === 0 ? "India" : locations;
        setUserState((prevState) => ({
          ...prevState,
          emailId: selectUserEmail,
          name: person_name,
          mobNo: contact_number,
          preferredLocation: preferredLocationn,
          skills: skills,
        }));
      }
      console.log("Resume uploaded successfully");
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading resume:", error);
    }
  };

  const signUpApi = async () => {
    try {
      const signupFormData = new FormData();
      Object.keys(userState).forEach((key) => {
        signupFormData.append(key, userState[key]);
      });

      const signupRes = await signupFD(signupFormData);

      if (signupRes.success === true) {
        if (userState.userType === "employee") {
          localStorage.setItem("access_token", signupRes.token);
          dispatch(setAuthToken(signupRes.token));
        }
        navigate("/home");
      } else {
        alert(signupRes.message);
      }
    } catch (error) {
      console.error("Error in signUpApi:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Handle Submit")
    if (!validateFields()) {
      console.log("Failed Validation")
      return;
    }

    const updatedUserState = { ...userState };
    if (userState.userType === "employee") {
      updatedUserState.companyName = "NA";
    } else if (userState.userType === "employer") {
      updatedUserState.skills = "NA";
    }

    setUserState(updatedUserState);

    setIsLoading(true); // Start the loader when the form is submitted

    try {
      await signUpApi();
      console.log("Form submitted!");
      dispatch(setUserRole(userState.userType));
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    } finally {
      setIsLoading(false); // Stop the loader after the process is complete
    }
    console.log("Form Submitted.");
  };

  const resumeField = (
    <>
      <small className="text-gray-400 dark:text-gray-300 mb-3">Upload your CV here!</small>
      <div className="input-group">
        <input
          type="file"
          name="resume"
          className="w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3"
          id="inputGroupFile02"
          onChange={handleFile}
        />
        <button
          className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          id="fileInput"
          onClick={handleFileUpload}
        >
          Upload
        </button>
      </div>
    </>
  );

  const skillsField = (
    <div>
      <input
        onChange={handleChange}
        type="text"
        name="skills"
        value={userState.skills}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.skills ? "is-invalid" : ""
          }`}
        disabled={hideFields}
        required
      />
      {errors.skills && <span className="text-danger">{errors.skills}</span>}
    </div>
  );

  const companyField = (
    <div>
      <input
        onChange={handleChange}
        type="text"
        name="companyName"
        value={userState.companyName}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.companyName ? "is-invalid" : ""
          }`}
        disabled={hideFields}
        required
      />
      {errors.companyName && (
        <span className="text-danger">{errors.companyName}</span>
      )}
    </div>
  );

  const cmpnyDetails = (
    <div>
      <input
        onChange={handleChange}
        type="text"
        name="companyLocation"
        value={userState.companyLocation}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.companyLocation ? "is-invalid" : ""
          }`}
        disabled={hideFields}
        required
      />
      {errors.companyLocation && (
        <span className="text-danger">{errors.companyLocation}</span>
      )}

      <label className="block text-gray-800 dark:text-gray-300">Company URL</label>
      <input
        onChange={handleChange}
        type="text"
        name="companywebsite"
        value={userState.companywebsite}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.companywebsite ? "is-invalid" : ""
          }`}
        disabled={hideFields}
        required
      />
      {errors.companywebsite && (
        <span className="text-danger">{errors.companywebsite}</span>
      )}
    </div>
  );

  const emplPrefrdLocation = (
    <>
      <input
        onChange={handleChange}
        type="text"
        name="preferredLocation"
        value={userState.preferredLocation}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.preferredLocation ? "is-invalid" : ""
          }`}
        disabled={hideFields}
        required
      />
      {errors.preferredLocation && (
        <span className="text-danger">{errors.preferredLocation}</span>
      )}
    </>
  );

  const noticePeriodField = (
    <div>
      <label className="block text-gray-800 dark:text-gray-300">Notice Period* <em className="text-gray-500">(in days)</em></label>
      <input
        onChange={handleChange}
        type="text"
        name="notice_period"
        value={userState.notice_period}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.notice_period ? "is-invalid" : ""
          }`}
        disabled={hideFields}
      />
      {errors.notice_period && <span className="text-danger">{errors.notice_period}</span>}
    </div>
  );

  const ctcField = (
    <div>
      <label className="block text-gray-800 dark:text-gray-300">CTC <em className="text-gray-500">(Annual)</em></label>
      <input
        onChange={handleChange}
        type="text"
        name="ctc"
        value={userState.ctc}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.ctc ? "is-invalid" : ""
          }`}
        disabled={hideFields}
      />
      {errors.ctc && <span className="text-danger">{errors.ctc}</span>}
    </div>
  );

  const qualificationField = (
    <div>
      <label className="block text-gray-800 dark:text-gray-300">Education</label>
      <input
        onChange={handleChange}
        type="text"
        name="education"
        value={userState.education}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.education ? "is-invalid" : ""
          }`}
        disabled={hideFields}
      />
      {errors.education && <span className="text-danger">{errors.education}</span>}
    </div>
  );

  const experienceField = (
    <div>
      <label className="block text-gray-800 dark:text-gray-300">Experience <em className="text-gray-500">(in years)</em></label>
      <input
        onChange={handleChange}
        type="text"
        name="experience"
        value={userState.experience}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.experience ? "is-invalid" : ""
          }`}
        disabled={hideFields}
      />
      {errors.experience && <span className="text-danger">{errors.experience}</span>}
    </div>
  );

  const designationField = (
    <div>
      <label className="block text-gray-800 dark:text-gray-300">Designation</label>
      <input
        onChange={handleChange}
        type="text"
        name="designation"
        value={userState.designation}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.designation ? "is-invalid" : ""
          }`}
        disabled={hideFields}
      />
      {errors.designation && <span className="text-danger">{errors.designation}</span>}
    </div>
  );

  const currentCompanyField = (
    <div>
      <label className="block text-gray-800 dark:text-gray-300">Current Company</label>
      <input
        onChange={handleChange}
        type="text"
        name="current_company"
        value={userState.current_company}
        className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.current_company ? "is-invalid" : ""
          }`}
        disabled={hideFields}
      />
      {errors.current_company && <span className="text-danger">{errors.current_company}</span>}
    </div>
  );

  return (
    <section className={`${darkMode ? 'dark' : ''} min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900`}>
      <div className="flex flex-col md:flex-row bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden w-full max-w-4xl">
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-6">{userState.userType === 'employee' ? "Your Career Starts Here" : "Start Hiring!"}</h2>
          <form onSubmit={handleSubmit} className="space-y-4 overflow-y-auto max-h-[60vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800">
            <div className="flex justify-center mb-4">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="userType"
                  value="employer"
                  onChange={handleChange}
                  id="flexRadioDefault1"
                  checked={userState.userType === "employer"}
                />
                <label
                  className="form-check-label text-gray-800 dark:text-gray-300"
                  htmlFor="flexRadioDefault1"
                  style={{ fontSize: "small" }}
                >
                  Employer
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="userType"
                  value="employee"
                  onChange={handleChange}
                  id="flexRadioDefault2"
                  checked={userState.userType === "employee"}
                />
                <label
                  className="form-check-label text-gray-800 dark:text-gray-300"
                  htmlFor="flexRadioDefault2"
                  style={{ fontSize: "small" }}
                >
                  Employee
                </label>
              </div>
            </div>

            {showEmployeeField && resumeField}
            <div>
              <label className="block text-gray-800 dark:text-gray-300">Name*</label>
              <input
                onChange={handleChange}
                name="name"
                type="text"
                value={userState.name}
                className={`w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3 ${errors.name ? "is-invalid" : ""
                  }`}
                disabled={hideFields}
                required
              />
              {errors.name && <span className="text-danger">{errors.name}</span>}
            </div>

            <div>
              <label className="block text-gray-800 dark:text-gray-300">Email*</label>
              <input
                onChange={handleChange}
                name="emailId"
                type="email"
                value={selectUserEmail}
                className="w-full px-3 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded border border-gray-400 dark:border-gray-600 focus:outline-none focus:border-blue-500 mb-3"
                disabled
                required
              />
              <small className="text-gray-400 dark:text-gray-300">{emailError}</small>
            </div>

            <div>
              <label className="block text-gray-800 dark:text-gray-300">Contact Number*</label>
              <PhoneNumberInput
                value={{ countryCode: userState.countryCode, mobNo: userState.mobNo }}
                onChange={handlePhoneNumberChange}
              />
              {errors.mobNo && <span className="text-danger">{errors.mobNo}</span>}
            </div>

            <div>
              <label className="block text-gray-800 dark:text-gray-300">
                {userState.userType === "employee" ? "Skills*" : "Company Name*"}
              </label>
              {showEmployeeField ? skillsField : companyField}
            </div>

            <div>
              <label className="block text-gray-800 dark:text-gray-300">
                {userState.userType === "employee" ? "Preferred Location*" : "Company Location"}
              </label>
              {showEmployeeField ? emplPrefrdLocation : cmpnyDetails}
            </div>

            {showEmployeeField && (
              <>
                {noticePeriodField}
                {ctcField}
                {qualificationField}
                {experienceField}
                {designationField}
                {currentCompanyField}
              </>
            )}
          </form>
          <button
            type="submit"
            className="w-full py-2 px-4 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
            onClick={handleSubmit}
          >
            Create an account
          </button>
          <div className="w-full flex justify-center items-center position: relative;">
            {isLoading && (
              <div className="flex justify-center items-center position: absolute ">
                <Loader />
              </div>
            )}
          </div>
        </div>
        <div className="w-full md:w-1/2 bg-blue-600 p-8 flex items-center justify-center">
          <div>
            <h2 className="text-3xl font-semibold text-white mb-4">HireMeNext</h2>
            <p className="text-white mb-6">Connecting Talent with Opportunity: Your Ultimate Destination for Personalized Job and HR Recruiting Solutions.</p>
            <p className="text-white">Over <span className="font-semibold">15.7k</span> Happy Users</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
