import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ onActionClick, currentStatus }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [status,setStatus] = useState(currentStatus);
    const dropdownRef = useRef(null);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log("Status prop: ",currentStatus)
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setStatus(currentStatus);
      }, [currentStatus]);
      

    const handleActionClick = (action) => {
        onActionClick(action);
        setStatus(action);
        setIsOpen(false); // Close the dropdown after an action is clicked
    };

    return (
        <div className="relative mx-16 inline-block text-left" ref={dropdownRef}>
            <button 
                id="dropdownDefaultButton" 
                onClick={toggleDropdown} 
                className="text-gray-800 bg-gray-100 hover:bg-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center" 
                type="button"
                aria-haspopup="true" 
                aria-expanded={isOpen}
            >
                {status}
                <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>

            {isOpen && (
                <div id="dropdown" className="absolute right-0 z-10 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-black dark:text-white" aria-labelledby="dropdownDefaultButton">
                        <li>
                            <button onClick={() => handleActionClick('Shortlist')} className="block w-full text-left px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-600 dark:hover:text-white">Shortlist</button>
                        </li>
                        <li>
                            <button onClick={() => handleActionClick('Reject')} className="block w-full text-left px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-600 dark:hover:text-white">Reject</button>
                        </li>
                        <li>
                            <button onClick={() => handleActionClick('Blacklist')} className="block w-full text-left px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-600 dark:hover:text-white">Blacklist</button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
