import React from "react";
import userAvatar from "../images/user.png"; // Fallback user avatar image
import Dropdown from "../Dropdown/Dropdown";
import { Button } from "@mui/material";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { PiHandbagSimpleFill } from "react-icons/pi";
import { GiMoneyStack } from "react-icons/gi";

import { ResumeDownload, UpdateStatus } from "../../utilities/apis";

const ApplicantsCard = ({ applicantDetails, jobId, onUpdateStatus, photo }) => {
  let skillsArray = [];

  // Ensure skills are formatted correctly as an array of strings
  if (applicantDetails.skills) {
    if (typeof applicantDetails.skills === "string") {
      skillsArray = applicantDetails.skills.split(",").slice(0, 10);
    } else {
      skillsArray = applicantDetails.skills.slice(0, 10);
    }
  }

  const skillsBadge = (
    <div className="flex flex-wrap space-x-2">
      {skillsArray.map((skill, index) => (
        <div
          className="bg-blue-50 rounded-lg px-2 py-1 m-1 text-sm text-gray-700"
          key={index}
        >
          {skill}
        </div>
      ))}
    </div>
  );

  // Join location array into a string
  const locationString =
    applicantDetails.location?.join(", ") || " ";

  const handleDownloadClick = async () => {
    try {
      const resumeRes = await ResumeDownload(
        applicantDetails.email,
        applicantDetails.name
      );
      if (resumeRes.success) {
        console.log("Resume: ", resumeRes);
      } else {
        console.error("Error downloading the file.");
      }
    } catch (error) {
      console.error("Error downloading the file.");
    }
  };

  const handleStatusClick = async (status) => {
    try {
      const response = await UpdateStatus(
        applicantDetails.email,
        jobId,
        status
      );
      if (response.success) {
        console.log("Applicant status updated successfully");
        onUpdateStatus(applicantDetails.email, status);
      } else {
        console.error("Error updating the status.");
      }
    } catch (error) {
      console.error("Error updating the status.");
    }
  };

  const base64Image = photo?`data:${photo.content_type
  };base64,${photo.content}`:null;

  return (
    <div className="flex p-4 bg-white rounded-lg shadow-md mt-2 mx-10">
      <div className="flex-1 flex flex-col items-start space-y-4 ml-4">
        <img
          className="w-20 h-20 rounded-full object-cover"
          src={photo? base64Image: userAvatar} // Use applicant photo or fallback to default
          alt="User pic"
        />
        <p className="text-lg font-semibold mt-2">{applicantDetails.name}</p>
        <div className="text-gray-700 flex gap-2">
          <MdEmail />
          {applicantDetails.email}
        </div>
        <div className="text-sm flex gap-2 text-gray-500">
          <FaPhoneVolume />
          <p>{applicantDetails.mobile_number}</p>
        </div>
        <div className="text-gray-500 flex gap-2">
          <MdLocationOn />
          {locationString}
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-center items-start pl-4 space-y-6">
        <div className="flex text-gray-700">
          <PiHandbagSimpleFill className="text-md text-gray-500" />
          <div className="ml-3">{applicantDetails.experience} Years</div>
        </div>
        <div className="flex text-gray-800">
          <GiMoneyStack className="text-md text-gray-500" />
          <div className="ml-3">{applicantDetails.ctc} per Year</div>
        </div>
        <div className="text-gray-700">
          Notice Period: {applicantDetails.notice_period}
        </div>
        <div className="text-gray-700">
          <div>{applicantDetails.designation}</div>
          <div className="text-gray-600">
            <em className="text-sm">{applicantDetails.current_company_name}</em>
          </div>
        </div>
        <div className="text-gray-700">{applicantDetails.jobPosition}</div>
      </div>

      <div className="flex-1 flex flex-col justify-center items-start space-y-4">
        <div className="text-gray-700">{applicantDetails.qualification}</div>
        <div>{skillsBadge}</div>
      </div>

      <div className="flex-1 flex flex-col justify-center space-y-2">
        <Dropdown
          onActionClick={(newStatus) => onUpdateStatus(applicantDetails.email, newStatus)}
          currentStatus={applicantDetails.status}
        />
        <Button variant="outlined" color="primary">
          Email
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDownloadClick}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default ApplicantsCard;
