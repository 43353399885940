import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice"; // Adjust path as necessary
import authReducer from "./authSlice";
import profileReducer from "./profileSlice";

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  profile: profileReducer,

  // add other slice reducers here
});

export default rootReducer;
