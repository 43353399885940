import Login from "./components/pages/Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./components/pages/Signup/Signup";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import PostJob from "./components/pages/PostJob/PostJob";
import JobDetail from "./components/pages/JobDetails/JobDetail";
import ViewApplicants from "./components/pages/ViewApplicants/ViewApplicants";
import Profile from "./components/ApplicantsCard/Profile/Profile";
import AdminDashboard from "./admin/AdminDashboard";


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/home' element={<Dashboard />} />
        <Route path='/postjob' element={<PostJob />} />
        <Route path='/view-applied/:jobId' element={<JobDetail />} />
        <Route path='/view-applicants' element={<ViewApplicants />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/admin-dashboard' element={<AdminDashboard/>}></Route>

      </Routes>
    </Router>
  );
}

export default App;
