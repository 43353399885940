import { useSelector } from "react-redux";
import NavBar from "../../Navbar";
import userAvatar from "../../images/user.png";
import React from "react";
import { PiHandbagSimpleFill } from "react-icons/pi";
import { GiMoneyStack } from "react-icons/gi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { LiaIndustrySolid } from "react-icons/lia";

import defaultCompanyLogo from '../../../assets/companyLogo.png'

function JobDetail() {
  const jobID = useSelector((state) => state.user.jobToView);
  const jobArray = useSelector((state) => state.user.jobArray);
  const jobDetails = jobArray.find((job) => job.job_id === jobID);
  const companyLogoContent = jobDetails.company_logo_content;
  const companyLogoType = jobDetails.company_logo_content_type;
  const base64Image = companyLogoContent && companyLogoType 
  ? `data:${companyLogoType};base64,${companyLogoContent}` 
  : null;
  console.log("BASE: ",base64Image)

  if (!jobDetails) {
    return <div>Loading...</div>;
  }

  const companyWebsite = jobDetails?.company_website
    ? jobDetails.company_website.startsWith("http")
      ? jobDetails.company_website
      : `http://${jobDetails.company_website}`
    : null;

  return (
    <>
      <div className="NavBar">
        <NavBar />
      </div>
      <div className="flex flex-col md:flex-row p-4 gap-4">
        <div className="w-full md:w-3/5 p-4">
          <div className="rounded-lg bg-white shadow-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-xl font-semibold">{jobDetails.job_title}</p>
                <p className="text-gray-500">{jobDetails.company_name}</p>
              </div>
              <div className="h-16 w-16 bg-gray-200 rounded-full flex items-center justify-center">
                <img
                  className="w-20 h-20 rounded-full object-cover"
                  src={ base64Image?base64Image:defaultCompanyLogo} // Use applicant photo or fallback to default
                  alt="User pic"
                />
              </div>
            </div>
            <div className="flex basis-20 gap-6">
              <div className="flex items-center text-gray-600 mb-4">
                <PiHandbagSimpleFill className="mr-2 text-lg" />
                <p>
                  {jobDetails.min_experience} - {jobDetails.max_experience}{" "}
                  years
                </p>
              </div>
              <div className="flex items-center text-gray-600 mb-4">
                <GiMoneyStack className="mr-2 text-lg" />
                <p>
                  {jobDetails.min_salary_compensation} -{" "}
                  {jobDetails.max_salary_compensation} LPA
                </p>
              </div>
            </div>
            <div className="flex basis-20 gap-6">
              <div className="flex items-center text-gray-600 mb-4">
                <FaMapMarkerAlt className="mr-2 text-lg" />
                <p>{jobDetails.location}</p>
              </div>
              <div className="flex items-center text-gray-600 mb-4">
                <LiaIndustrySolid className="mr-2 text-lg" />
                <p>{jobDetails.industry}</p>
              </div>
            </div>

            <div className="flex flex-wrap text-gray-600 mb-4">
              {jobDetails.req_skills.split(",").map((skill, index) => (
                <div
                  key={index}
                  className="mr-2 mb-2 px-2 py-1 border border-gray-300 rounded-full"
                >
                  {skill}
                </div>
              ))}
            </div>
          </div>

          {/* Job Description Card */}
          <div className="rounded-lg bg-white shadow-lg p-6 mt-4 h-60 min-h-64">
            <div>
              <div className="flex justify-between items-center mb-2">
                <p className="text-xl font-semibold">Job Description</p>
                {companyWebsite && (
                  <p className="text-gray-600">
                    Company Website:{" "}
                    <a
                      href={companyWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {companyWebsite}
                    </a>
                  </p>
                )}
              </div>
              <p className="text-gray-600">{jobDetails.job_description}</p>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/4 p-4">
          {/* Placeholder for additional content or sidebar if needed */}
        </div>
      </div>
    </>
  );
}

export default JobDetail;
